import api from '@/api'

const state = {
  loading: false,
  error: null
}

const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_ERROR(state, error) {
    state.error = error
  }
}

const actions = {
  async updateProfile({ commit, dispatch }, profileData) {
    commit('SET_LOADING', true)
    try {
      await api.profile.updateProfile(profileData)
      dispatch('auth/getProfile', null, { root: true })
    } catch (error) {
      commit('SET_ERROR', error.message)
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async changePassword({ commit }, passwordData) {
    commit('SET_LOADING', true)
    try {
      await api.profile.changePassword(passwordData)
    } catch (error) {
      commit('SET_ERROR', error.message)
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async uploadAvatar({ commit, dispatch }, formData) {
    commit('SET_LOADING', true)
    try {
      await api.profile.uploadAvatar(formData)
      dispatch('auth/getProfile', null, { root: true })
    } catch (error) {
      commit('SET_ERROR', error.message)
    } finally {
      commit('SET_LOADING', false)
    }
  }
}

const getters = {
  loading: state => state.loading,
  error: state => state.error
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
