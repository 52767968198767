import request from '../request'

/**
 * 用户认证相关接口
 */
export default {
  /**
   * 用户注册
   * @param {Object} data - 注册信息
   * @param {string} data.email - 邮箱
   * @param {string} data.password - 密码
   * @param {string} data.display_name - 显示名称
   * @param {string} [data.first_name] - 名字（可选）
   * @param {string} [data.last_name] - 姓氏（可选）
   */
  register(data) {
    return request({
      url: '/user/auth/register',
      method: 'post',
      data
    })
  },

  /**
   * 用户登录
   * @param {Object} data - 登录信息
   * @param {string} data.email - 邮箱
   * @param {string} data.password - 密码
   */
  login(data) {
    return request({
      url: '/user/auth/login',
      method: 'post',
      data
    })
  },

  /**
   * 验证邮箱
   * @param {string} token - 验证token
   */
  verifyEmail(token) {
    return request({
      url: `/user/auth/verify-email/${token}`,
      method: 'post'
    })
  },

  /**
   * 重新发送验证邮件
   */
  resendVerification() {
    return request({
      url: '/user/auth/resend-verification',
      method: 'post'
    })
  },

  /**
   * 获取用户信息
   */
  getProfile() {
    return request({
      url: '/user/auth/profile',
      method: 'get'
    })
  },

  /**
   * 更新用户信息
   * @param {Object} data - 用户信息
   * @param {string} [data.display_name] - 显示名称
   * @param {string} [data.first_name] - 名字
   * @param {string} [data.last_name] - 姓氏
   * @param {string} [data.email] - 邮箱
   */
  updateProfile(data) {
    return request({
      url: '/user/auth/profile',
      method: 'put',
      data
    })
  },

  /**
   * 修改密码
   * @param {Object} data - 密码信息
   * @param {string} data.oldPassword - 旧密码
   * @param {string} data.newPassword - 新密码
   */
  changePassword(data) {
    return request({
      url: '/user/auth/password',
      method: 'put',
      data
    })
  },

  /**
   * 上传头像
   * @param {FormData} file - 头像文件
   */
  uploadAvatar(file) {
    return request({
      url: '/user/auth/avatar',
      method: 'post',
      data: file,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  /**
   * 找回密码
   * @param {string} email - 用户邮箱
   */
  forgotPassword(email) {
    return request({
      url: '/user/auth/forgot-password',
      method: 'post',
      data: { email }
    })
  },

  /**
   * 重置密码
   * @param {string} password - 新密码
   * @param {string} token - 重置密码token
   */
  resetPassword(password, token) {
    return request({
      url: '/user/auth/reset-password',
      method: 'post',
      data: { password, token }
    })
  }
}