import api from '@/api'
import { IMAGE_URL_PREFIX } from '@/constants'

const state = {
  token: localStorage.getItem('token') || '',
  user: JSON.parse(localStorage.getItem('user') || 'null'),
  isAuthenticated: !!localStorage.getItem('token'),
  loading: false,
  error: null
}

const mutations = {
  SET_TOKEN(state, token) {
    state.token = token
    state.isAuthenticated = !!token
    if (token) {
      localStorage.setItem('token', token)
    } else {
      localStorage.removeItem('token')
    }
  },
  
  SET_USER(state, user) {
    if (user && user.avatar && !user.avatar.startsWith('http')) {
      user.avatar = `${IMAGE_URL_PREFIX}/${user.avatar}`
    }
    state.user = user
    if (user) {
      localStorage.setItem('user', JSON.stringify(user))
    } else {
      localStorage.removeItem('user')
    }
  },
  
  CLEAR_AUTH(state) {
    state.token = ''
    state.user = null
    state.isAuthenticated = false
    localStorage.removeItem('token')
    localStorage.removeItem('user')
  }
}

const actions = {
  async login({ commit }, credentials) {
    try {
      const response = await api.auth.login(credentials)
      console.log('Login response:', response) // 添加日志
      if (response.success && response.data) {
        commit('SET_TOKEN', response.data.token)
        commit('SET_USER', response.data.user)
        return response.data
      }
      throw new Error(response.message || '登录失败')
    } catch (error) {
      console.error('Login error:', error)
      throw error
    }
  },
  
  async logout({ commit }) {
    commit('CLEAR_AUTH')
  },

  async fetchUserProfile({ commit }) {
    try {
      const response = await api.auth.getProfile()
      if (response.success && response.data) {
        commit('SET_USER', response.data)
        return response.data
      }
      throw new Error(response.message || '获取用户资料失败')
    } catch (error) {
      console.error('Fetch profile error:', error)
      throw error
    }
  },

  async verifyEmail({ commit }, token) {
    try {
      const response = await api.auth.verifyEmail(token)
      if (response.success) {
        return response.data
      }
      throw new Error(response.message || '邮箱验证失败')
    } catch (error) {
      console.error('Verify email error:', error)
      throw error
    }
  },

  async resendVerification({ commit }) {
    try {
      const response = await api.auth.resendVerification()
      if (response.success) {
        return response.data
      }
      throw new Error(response.message || '重新发送验证邮件失败')
    } catch (error) {
      console.error('Resend verification email error:', error)
      throw error
    }
  },

  async uploadAvatar({ commit, state }, formData) {
    try {
      const response = await api.auth.uploadAvatar(formData)
      if (response.success) {
        // 更新用户信息中的头像
        const user = { ...state.user, avatar: response.avatarUrl }
        commit('SET_USER', user)
        return response
      }
      throw new Error(response.message || '头像上传失败')
    } catch (error) {
      console.error('Upload avatar error:', error)
      throw error
    }
  },

  async updateProfile({ commit }, profileData) {
    try {
      const response = await api.auth.updateProfile(profileData)
      if (response.success && response.data) {
        commit('SET_USER', response.data)
        return response.data
      }
      throw new Error(response.message || '更新用户资料失败')
    } catch (error) {
      console.error('Update profile error:', error)
      throw error
    }
  },

  async register({ commit }, data) {
    try {
      const response = await api.auth.register(data)
      if (response.success) {
        return response.data
      }
      throw new Error(response.message || 'Registration failed')
    } catch (error) {
      throw new Error(error.message || 'Registration failed')
    }
  },

  async forgotPassword({ commit }, { email }) {
    try {
      const response = await api.auth.forgotPassword(email)
      if (response.success) {
        return response.data
      }
      throw new Error(response.message || 'Failed to send reset link')
    } catch (error) {
      throw new Error(error.message || 'Failed to send reset link')
    }
  },

  async resetPassword({ commit }, { password, token }) {
    try {
      const response = await api.auth.resetPassword(password, token)
      return response.data
    } catch (error) {
      throw error
    }
  }
}

const getters = {
  isAuthenticated: state => state.isAuthenticated,
  user: state => state.user,
  token: state => state.token
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}