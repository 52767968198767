import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import i18n from './locales'
import 'ant-design-vue/dist/reset.css'

const app = createApp(App)

// 设置网页标题
document.title = 'TAPME'

// 支持的语言列表
const supportedLanguages = ['en', 'fr', 'zh-TW', 'zh-CN']

// 添加全局 mixin 处理语言
app.mixin({
  mounted() {
    // 只在根组件中执行一次
    if (this.$parent === null) {
      // 检查URL中是否有语言参数
      const urlParams = new URLSearchParams(window.location.search)
      const langParam = urlParams.get('lang')
      
      if (langParam && supportedLanguages.includes(langParam)) {
        // 如果URL中有有效的语言参数，则使用该语言并保存到localStorage
        i18n.global.locale.value = langParam
        localStorage.setItem('language', langParam)
      }
    }
  }
})

// 监听路由变化，确保语言参数一致性
router.beforeEach((to, from, next) => {
  const langParam = to.query.lang
  if (langParam && supportedLanguages.includes(langParam)) {
    // 如果URL中有有效的语言参数，强制使用该语言并保存到localStorage
    i18n.global.locale.value = langParam
    localStorage.setItem('language', langParam)
  }
  next()
})

app.use(router)
   .use(store)
   .use(Antd)
   .use(i18n)
   .mount('#app')