export default {
  token: {
    colorPrimary: '#333333',
    colorInfo: '#333333',
    colorLink: '#333333',
    colorPrimaryHover: '#444444',
    colorPrimaryActive: '#444444',
    colorBgContainer: '#ffffff',
    colorBorder: '#333333',
    // 设置全局尺寸
    sizeUnit: 4,
    sizeStep: 4,
    controlHeight: 40
  },
  components: {
    Button: {
      colorPrimary: '#333333',
      colorPrimaryHover: '#444444',
      colorPrimaryActive: '#444444',
      defaultBg: '#ffffff',
      defaultColor: '#333333',
      defaultBorderColor: '#333333',
      controlHeight: 40,
      paddingInline: 24
    },
    Input: {
      controlHeight: 40,
      paddingBlock: 8,
      paddingInline: 16
    },
    Select: {
      controlHeight: 40,
      paddingBlock: 8,
      paddingInline: 16
    },
    DatePicker: {
      controlHeight: 40,
      paddingBlock: 8,
      paddingInline: 16
    },
    TimePicker: {
      controlHeight: 40,
      paddingBlock: 8,
      paddingInline: 16
    },
    Result: {
      colorInfo: '#333333'
    }
  }
}
