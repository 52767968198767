import { createI18n } from 'vue-i18n'
import en from './en.json'
import fr from './fr.json'
import zhCN from './zh-CN.json'
import zhTW from './zh-TW.json'

// 获取系统默认语言
const getDefaultLanguage = () => {
  // 获取浏览器语言设置
  const browserLang = navigator.language || navigator.userLanguage
  const lang = browserLang.toLowerCase()
  
  // 检查是否为法语环境
  if (lang.startsWith('fr')) {
    return 'fr'
  }
  
  // 检查是否为中文环境
  if (lang.startsWith('zh')) {
    // 检查是否为繁体中文地区
    if (lang === 'zh-tw' || lang === 'zh-hk' || lang === 'zh-mo' || 
        lang.startsWith('zh-hant') || lang.includes('tw') || 
        lang.includes('hk') || lang.includes('mo')) {
      return 'zh-TW'
    }
    // 其他中文环境默认使用简体中文
    return 'zh-CN'
  }
  
  // 其他语言默认使用英文
  return 'en'
}

// 从 localStorage 获取保存的语言设置
const getSavedLanguage = () => {
  const savedLang = localStorage.getItem('language')
  if (savedLang) {
    return savedLang
  }
  return getDefaultLanguage()
}

// 监听系统语言变化
const watchSystemLanguage = (i18n) => {
  window.addEventListener('languagechange', () => {
    const newLang = getDefaultLanguage()
    if (!localStorage.getItem('language')) {
      i18n.global.locale.value = newLang
    }
  })
}

const messages = {
  en,
  fr,
  'zh-TW': zhTW,
  'zh-CN': zhCN
}

const i18n = createI18n({
  legacy: false,
  locale: getSavedLanguage(),
  fallbackLocale: 'en',
  messages,
  sync: true,
  silentTranslationWarn: true,
  missingWarn: false,
  fallbackWarn: false
})

// 启动语言变化监听
watchSystemLanguage(i18n)

export default i18n