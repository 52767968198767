import auth from './modules/auth'
import card from './modules/card'
import upload from './modules/upload'
import page from './modules/page'

export default {
  auth,
  card,
  upload,
  page
}