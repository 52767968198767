import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

const routes = [
  {
    path: '/i/:cardId',
    component: () => import('@/layouts/PublicLayout.vue'),
    children: [
      {
        path: '',
        name: 'PublicCardProfile',
        component: () => import('@/views/public/CardProfile.vue'),
        meta: { requiresAuth: false }
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
        meta: { requiresAuth: false }
      },
      {
        path: 'cards',
        name: 'CardList',
        component: () => import('@/views/cards/CardList.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'cards/bind',
        name: 'BindCard',
        component: () => import('@/views/cards/BindCard.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'cards/:id',
        name: 'CardDetail',
        component: () => import('@/views/cards/CardDetail.vue')
      },
      {
        path: 'cards/:id/edit',
        name: 'EditCard',
        component: () => import('@/views/cards/EditCard.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import('@/views/user/Profile.vue'),
        meta: { requiresAuth: true, requiresVerified: true }
      },
      {
        path: 'settings',
        name: 'Settings',
        component: () => import('@/views/user/Settings.vue'),
        meta: { requiresAuth: true, requiresVerified: true }
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/auth/Login.vue'),
        meta: { guest: true }
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/auth/Register.vue'),
        meta: { guest: true }
      },
      {
        path: 'verify-email',
        name: 'VerifyEmail',
        component: () => import('@/views/auth/VerifyEmail.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'verify-email/:token',
        name: 'VerifyEmailToken',
        component: () => import('@/views/auth/VerifyEmail.vue'),
        meta: { requiresAuth: false }
      },
      {
        path: 'forgot-password',
        name: 'ForgotPassword',
        component: () => import('@/views/auth/ForgotPassword.vue'),
        meta: { guest: true }
      },
      {
        path: 'reset-password/:token',
        name: 'ResetPassword',
        component: () => import('@/views/auth/ResetPassword.vue'),
        meta: { guest: true }
      },
      {
        path: '/pages/:code',
        name: 'page',
        component: () => import('@/views/pages/PageView.vue'),
        meta: {
          title: 'Page'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const token = store.state.auth.token
  const user = store.state.auth.user

  // 如果有token但没有用户信息，先获取用户信息
  if (token && !user) {
    try {
      await store.dispatch('auth/fetchUserProfile')
    } catch (error) {
      console.error('获取用户信息失败:', error)
      // 如果获取用户信息失败，清除token并跳转到登录页
      store.dispatch('auth/logout')
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
      return
    }
  }

  // 需要登录的页面
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
      return
    }

    // 检查邮箱验证状态
    const currentUser = store.state.auth.user
    if (to.matched.some(record => record.meta.requiresVerified)) {
      if (!currentUser || !currentUser.is_verified) {
        // 检查是否在注册3天内
        const registrationDate = new Date(currentUser.created_at)
        const threeDaysAfterRegistration = new Date(registrationDate.getTime() + (3 * 24 * 60 * 60 * 1000))
        const now = new Date()
        
        if (now > threeDaysAfterRegistration) {
          next('/verify-email')
          return
        }
      }
    }
  }

  // 已登录用户不能访问登录和注册页面
  if (token && (to.path === '/login' || to.path === '/register')) {
    next('/')
    return
  }

  next()
})

export default router