import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { message } from 'ant-design-vue'

// 创建axios实例
const request = axios.create({
  baseURL: process.env.VUE_APP_API_URL || 'http://api.tapme.cc',
  timeout: 30 * 1000,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
})

// 请求拦截器
request.interceptors.request.use(
  config => {
    // 打印 baseURL
    console.log('Base URL:', config.baseURL)

    // 根据请求URL判断是否为管理员接口
    const isAdminApi = config.url.startsWith('/admin')

    // 获取对应的token
    const token = isAdminApi
      ? store.state.admin?.token
      : store.state.auth?.token

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器
request.interceptors.response.use(
  response => {
    const data = response.data
    console.log('API Response:', data)

    // 特殊处理 profile 接口
    if (response.config.url === '/user/auth/profile' && response.config.method === 'get') {
      store.commit('auth/SET_USER', data.data || data)
    }

    // 统一处理返回格式
    if (data.success !== undefined) {
      // 如果后端返回了 success 字段，直接返回整个响应数据
      // if (data.message && data.success) {
      //   message.success(data.message)
      // }
      return {
        success: true,
        data: data.data || data,
        message: data.message || ''
      }
    } else {
      // 如果后端没有返回 success 字段，我们包装一下返回格式
      return {
        success: true,
        data: data,
        message: ''
      }
    }
  },
  error => {
    // 统一错误处理
    const { response } = error
    let errorMessage = '请求失败'

    if (response) {
      const { status, data } = response

      switch (status) {
        case 400:
          errorMessage = data.message || '请求参数错误'
          break
        case 401:
          // 检查是否是登录接口
          if (response.config.url === '/user/auth/login') {
            errorMessage = data.message || '账户或密码错误'
          } else {
            errorMessage = '未授权，请重新登录'
            store.dispatch('auth/logout')
            router.push('/login')
          }
          break
        case 403:
          errorMessage = '拒绝访问'
          break
        case 404:
          errorMessage = '请求错误，未找到该资源'
          break
        case 405:
          errorMessage = '请求方法未允许'
          break
        case 408:
          errorMessage = '请求超时'
          break
        case 500:
          errorMessage = '服务器端出错'
          break
        case 501:
          errorMessage = '网络未实现'
          break
        case 502:
          errorMessage = '网络错误'
          break
        case 503:
          errorMessage = '服务不可用'
          break
        case 504:
          errorMessage = '网络超时'
          break
        case 505:
          errorMessage = 'http版本不支持该请求'
          break
        default:
          errorMessage = data.message || `连接错误${status}`
      }
    }

    message.error(errorMessage)
    return Promise.reject(error)
  }
)

export default request