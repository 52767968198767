import request from '../request'

/**
 * 卡片相关接口
 */
export default {
  /**
   * 获取用户的卡片列表
   */
  getUserCards() {
    return request({
      url: '/user/cards',
      method: 'get'
    })
  },

  /**
   * 获取卡片详情
   * @param {number} id - 卡片ID
   */
  getCard(id) {
    return request({
      url: `/user/cards/${id}`,
      method: 'get'
    })
  },

  /**
   * 获取公开卡片信息
   * @param {string} cardId - 卡片ID或卡号
   */
  getPublicCard(cardId) {
    return request({
      url: `/user/cards/${cardId}/public`,
      method: 'get'
    })
  },

  /**
   * 创建卡片
   * @param {Object} data - 卡片信息
   * @param {string} data.name - 卡片名称
   * @param {string} data.title - 职位
   * @param {string} data.company - 公司
   * @param {string} data.phone - 电话
   * @param {string} data.email - 邮箱
   * @param {string} data.website - 网站
   * @param {string} data.avatar - 头像
   * @param {Array} data.social_links - 社交链接
   * @param {string} data.bio - 个人简介
   */
  createCard(data) {
    return request({
      url: '/user/cards',
      method: 'post',
      data
    })
  },

  /**
   * 更新卡片
   * @param {number} id - 卡片ID
   * @param {Object} data - 卡片信息
   */
  updateCard(id, data) {
    delete data.country_key
    delete data.country_key_2
    delete data.country_code
    delete data.country_code_2
    delete data.phone_code
    delete data.phone_code_2
    return request({
      url: `/user/cards/${id}`,
      method: 'put',
      data
    })
  },

  /**
   * 删除卡片
   * @param {number} id - 卡片ID
   */
  deleteCard(id) {
    return request({
      url: `/user/cards/${id}`,
      method: 'delete'
    })
  },

  /**
   * 检查卡号是否有效
   * @param {string} cardNumber - 卡号
   */
  checkCardNumber(cardNumber) {
    return request({
      url: '/user/cards/check/' + cardNumber,
      method: 'get',
      data: { card_number: cardNumber }
    })
  },

  /**
   * 绑定卡片
   * @param {Object} data - 卡片信息
   */
  bindCard(data) {
    return request({
      url: '/user/cards/bind',
      method: 'post',
      data
    })
  },

  /**
   * 上传图片
   * @param {File} file - 图片文件
   */
  uploadPhoto(file) {
    const formData = new FormData()
    formData.append('photo', file)

    return request({
      url: '/upload/photo',
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    })
  }
}