import api from '@/api'

const state = {
  cards: [],
  currentCard: null,
  loading: false,
  error: null
}

const mutations = {
  SET_CARDS(state, cards) {
    state.cards = cards
  },
  SET_CURRENT_CARD(state, card) {
    state.currentCard = card
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_ERROR(state, error) {
    state.error = error
  }
}

const actions = {
  async fetchCards({ commit }) {
    commit('SET_LOADING', true)
    try {
      const response = await api.card.getCards()
      if (response.success) {
        commit('SET_CARDS', response.data)
        return { success: true }
      }
      return { success: false, error: response.message }
    } catch (error) {
      commit('SET_ERROR', error.message)
      return { success: false, error: error.message }
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async fetchCard({ commit }, id) {
    commit('SET_LOADING', true)
    try {
      const response = await api.card.getCard(id)
      if (response.success) {
        commit('SET_CURRENT_CARD', response.data)
        return { success: true }
      }
      return { success: false, error: response.message }
    } catch (error) {
      commit('SET_ERROR', error.message)
      return { success: false, error: error.message }
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async createCard({ commit, dispatch }, cardData) {
    commit('SET_LOADING', true)
    try {
      const response = await api.card.createCard(cardData)
      if (response.success) {
        await dispatch('fetchCards')
        return { success: true }
      }
      return { success: false, error: response.message }
    } catch (error) {
      commit('SET_ERROR', error.message)
      return { success: false, error: error.message }
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async updateCard({ commit, dispatch }, { id, data }) {
    commit('SET_LOADING', true)
    try {
      const response = await api.card.updateCard(id, data)
      if (response.success) {
        await dispatch('fetchCards')
        return { success: true }
      }
      return { success: false, error: response.message }
    } catch (error) {
      commit('SET_ERROR', error.message)
      return { success: false, error: error.message }
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async deleteCard({ commit, dispatch }, id) {
    commit('SET_LOADING', true)
    try {
      const response = await api.card.deleteCard(id)
      if (response.success) {
        await dispatch('fetchCards')
        return { success: true }
      }
      return { success: false, error: response.message }
    } catch (error) {
      commit('SET_ERROR', error.message)
      return { success: false, error: error.message }
    } finally {
      commit('SET_LOADING', false)
    }
  }
}

const getters = {
  cards: state => state.cards,
  currentCard: state => state.currentCard,
  loading: state => state.loading,
  error: state => state.error
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
