import request from '../request'

/**
 * 文件上传相关接口
 */
export default {
  /**
   * 上传文件
   * @param {FormData} data - 文件数据
   * @param {string} type - 文件类型(image/file)
   */
  upload(data, type = 'image') {
    return request({
      url: '/upload',
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: {
        file: data,
        type
      }
    })
  },

  /**
   * 删除文件
   * @param {string} url - 文件URL
   */
  delete(url) {
    return request({
      url: '/upload',
      method: 'delete',
      data: { url }
    })
  }
} 